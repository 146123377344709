.crumb:before {
	background: url('../../icons/separator.svg') no-repeat;
}

.crumb:first-child:before {
	background: none;
}

.crumb [aria-current='true'] {
	@apply pointer-events-none;
}
.wrapper::after {
	background: linear-gradient(
		90deg,
		#fff0,
		theme('backgroundColor.main') 80%
	);
}
