.interactiveItem {
  & button {
    @apply text-left;
  }

  & button:hover,
  & button:focus {
    @apply text-secondary;
  }
}

.active {
  @apply bg-blue-900;
}

.blur button {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.NavCarousel {

  & :global .slick-track {
	@apply h-auto !important;
  }

  & :global .slick-slide {
    @apply border-b border-gray-500 p-4 border-l-0 border-r-0 border-t-0 cursor-pointer;

    & :global button {
      @apply font-bold hover:text-secondary focus:text-secondary;
    }

  }

  & :global .slick-slide.slick-current {
    & :global button {
      @apply text-secondary underline;
    }
  }

  & :global .slick-slide:last-child {
    @apply border-0;
  }
}

.MainSlider {
  & :global .slick-arrow {
	@apply md:h-10 md:w-10 md:bottom-[26px];
  }
}
