.nav a {
	text-decoration: none;
}

.nav > ul > li {
	@apply relative border-b border-gray-400 bg-gray-200 lg:static lg:border-0 lg:bg-transparent lg:pb-2 lg:pl-2 lg:pt-4 lg:text-center;
	@screen lg {
		&:global.nav-text-white {
			@apply text-white lg:transition-opacity lg:duration-300;
			transition: opacity 0.3s ease-in-out;
			& .subNavButton {
				background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zMzMiIGhlaWdodD0iNi42NjciIHZpZXdCb3g9IjAgMCAxMy4zMzMgNi42NjciPjxwYXRoIGQ9Ik03LDEwbDYuNjY3LDYuNjY3TDIwLjMzMywxMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03IC0xMCkiIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
				transition: transform 1s;
			}
		}
	}
	&:first-child {
		@apply border-t lg:border-0;
	}
}

.nav > ul li ul {
	@apply hidden pl-2 text-left lg:invisible lg:absolute lg:top-24 lg:block lg:max-w-xs lg:rounded-md lg:pl-0 lg:opacity-0 lg:shadow-primary;
	@screen lg {
		&:global(.active) {
			@apply block;
		}
	}
}

.nav > ul li ul li ul {
	@apply lg:max-h-96;
}

.nav > ul li ul li.open ul {
	@apply left-full top-0 mt-0 rounded-tl-none lg:w-fit;
}

.nav > ul li ul li a {
	@apply pl-2;
}

.nav > ul > li > ul {
	@apply z-[110] lg:text-gray-600;
}
.nav > ul > li > ul li a {
	@apply block py-2 pl-6 pr-4 text-blue-900 lg:px-4;
}

.nav > ul > li > ul li:first-child > a {
	@apply lg:border-0;
}
.nav > ul > li > ul > li:first-child > a {
	@apply border-0;
}

.nav > ul > li > ul li:hover {
	@apply lg:bg-gray-100 lg:text-accent;
}

.nav > ul > li > a {
	@apply inline-block py-2 pl-4 pr-10 lg:p-0;
}
.nav > ul > li > a:after {
	@apply lg:mx-auto lg:mt-[2px] lg:block lg:h-[3px] lg:max-w-[32px] lg:rounded-sm lg:transition lg:duration-300 lg:content-[''];
}

.nav > ul > li > a:hover:not([aria-haspopup='true']):after,
.nav li.selected > a:after {
	@apply translate-x-0 bg-theme-main;
}

.nav > ul li.open {
	@apply text-theme-main;
}

.nav li:not(.selected) {
	@apply font-normal;
}
.nav li.selected {
	@apply font-bold;
}

.nav > ul li.open > ul {
	@apply visible block opacity-100 shadow-none;
}

.nav > ul li.open > .subNavButton {
	@apply -rotate-90 bg-center lg:rotate-180 lg:bg-bottom;
}

.nav .subNavButton {
	@apply absolute right-0 ml-2 mr-4 mt-4 inline-block h-[14px] w-[14px] rotate-90 bg-center bg-no-repeat lg:static lg:mr-0 lg:mt-5 lg:rotate-0 lg:bg-top;
	background-image: url('../../icons/separator.svg');
	@screen lg {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zMzMiIGhlaWdodD0iNi42NjciPjxwYXRoIGQ9Im0wIDAgNi42NjcgNi42NjdMMTMuMzMzIDBaIiBmaWxsPSIjMDIyNTNlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
	}
}

.mainNavListItem {
	@apply mr-4;
}

.subNavListItem {
	@apply flex flex-col items-start justify-between border-t-0 p-0 lg:relative lg:flex-row lg:border-b lg:border-b-[#eeeeee] lg:px-2 lg:py-1;
	@screen lg {
		align-items: normal;
	}
}

.nav > ul li > .subNavButton.subNavChevron {
	@apply mb-12 mt-[11px] rotate-90 scale-150 bg-center bg-no-repeat lg:mb-0 lg:rotate-0;
	background-image: url('../../icons/separator.svg');
}

.nav > ul li.open > .subNavButton.subNavChevron {
	@apply -rotate-90 scale-150 lg:rotate-0;
}
