.tabsStack {
	@apply flex items-stretch;
	&:global(.RRT__accordion) {
		@apply flex-col;
		& .keys-block {
			@apply mr-0 w-full;
		}
		& .keys-container {
			@apply flex-row flex-wrap;
			& div {
				@apply xs:w-[48%] xs:ml-[4%] xs:odd:ml-0 w-full sm:ml-[3.5%] sm:w-[31%];
				@screen sm {
					&:nth-child(3n + 1) {
						@apply ml-0;
					}
				}
			}
		}
		& .phase-overview {
			@apply mt-8 w-full flex-auto pl-0;
		}
		& .overview-cards-container {
			@apply flex-row flex-wrap;
			& div {
				@apply xs:w-[48%]  xs:ml-[4%] xs:odd:ml-0 w-full sm:ml-[3.5%] sm:w-[31%] md:odd:ml-[3.5%];
				@screen md {
					&:nth-child(3n + 1) {
						@apply ml-0;
					}
				}
			}
		}
		& .pipeline-cards-container {
			@apply mt-4;
		}
	}
}

.tabsScroll {
	@apply flex items-stretch overflow-x-scroll scroll-smooth md:overflow-hidden lg:overflow-hidden;
}

.tabsScroll .tab {
	@apply whitespace-nowrap;
}

.tab {
	@apply flex flex-1 flex-grow cursor-pointer items-center border-l-2 border-gray-500 bg-gray-400 px-6 py-4 text-sm text-gray-600 hover:bg-gray-300;
	&:global(.RRT__tab--first) {
		@apply border-l-0;
	}
	&:global(.RRT__tab--collapsed) {
		@apply border-b border-l-0 bg-gray-300 text-blue-900;
		& .indicator {
			@apply inline-block transition-all duration-300;
		}
	}
	&:global(.RRT__tab--selected) {
		@apply bg-gray-200 font-bold text-blue-900;
		& .indicator {
			@apply rotate-45;
		}
	}
}

.panel {
	@apply max-h-[80vh] overflow-auto p-6 md:p-8;
}

.panelNoOverflow {
	@apply p-6 md:p-8;
}

.pipeCard {
	@apply sm:w-[calc(50%-0.75rem)];
}
.tabContent {
	display: flex;
	flex-direction: column;
}

/* Hide inactive tab content visually but keep in DOM */
.inactiveTab {
	display: none;
	visibility: hidden;
	height: 0;
}
