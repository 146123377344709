.shareBlockShowOnHover {
  @apply inline-block relative pr-[45px];

  & p {
    @apply inline-block leading-[30px];
  }

  &.noText {
    @apply pr-0;
  }

  &.mediumIconSize {
    & ul li {
      &:first-child a {
		@apply w-12 h-12;
        & svg {
		  @apply w-7 h-7;
        }
      }

      & a {
		@apply w-[42px] h-[42px];
        & svg {
		  @apply w-[22px] h-[22px];
        }
      }
    }
  }

  & ul {
    @apply border border-transparent inline-block absolute top-0 right-0 z-10;

    & li {
	  @apply border-none h-px -m-px overflow-hidden p-0 absolute w-px;
      clip: rect(0 0 0 0);
      &:first-child {
		@apply border-none h-auto m-0 overflow-visible p-0 static w-auto;
        clip: auto;

        & a {
		  @apply w-8 h-8 ml-0;

          & svg {
			@apply w-[18px] h-[18px];
          }
        }
      }

      & a {
        @apply block w-[26px] h-[26px] mt-0 mx-auto mb-1;

        & svg {
		  @apply w-[14px] h-[14px];
        }
      }
    }
  }

  &:hover,
  &:focus {
    & ul {
      @apply border border-gray-500 bg-white rounded-full;

      & li {
		@apply border-none h-auto m-0 overflow-visible p-0 static w-auto;
        clip: auto;
      }
    }
  }

  &:global(.focus) {
    & ul {
      @apply border border-gray-500 bg-white rounded-full;

      & li {
		@apply border-none h-auto m-0 overflow-visible p-0 static w-auto;
      }
    }
  }
}
