.arrow-overlap {
	transform: rotate(90deg);
}

@screen md {
	.arrow-overlap {
	  transform: translateX(-64%);
	}

}

 .StepContainer:nth-child(n + 3) {
    @apply md:mt-12;
  }

  .StepContainer:nth-child(2n + 1) .arrow-overlap {
    @apply md:hidden;
  }

  .StepContainer:nth-child(2n + 1) .arrow-overlap {
    @apply lg:block;
  }

  .StepContainer:nth-child(n + 3):nth-child(-n + 4) {
    @apply lg:mt-0;
  }

  .StepContainer:nth-child(4n + 1) .arrow-overlap {
    @apply lg:hidden;
  }
