.Thumbnails {
	:global(.slick-slide){
		@apply max-w-[20%]
	}

	:global(.slick-current .thumbnail-container) {
		@apply border-blue-400;
	}

	:global(.slick-slide:not(.slick-current) .thumbnail-container:hover) {
		@apply border-gray-500;
	}

	:global(.slick-slide:not(.slick-current) .thumbnail-container:focus) {
		@apply border-gray-500;
	}

	:global(.thumbnail) {
		@apply block;
	}

	:global(.slick-prev),
	:global(.slick-next) {
		@apply top-1/2 -translate-y-[50%] md:w-12 md:h-12;
	}

	:global(.slick-prev) {
		@apply left-auto right-[50px] md:right-[92px];
	}

	:global(.slick-next) {
		@apply right-4 md:right-8;
	}
}

.ImageGalleryWithPagination {
	 .Thumbnails {
		:global(.slick-list) {
			@apply w-3/5;
		}
	}
}
