.full-width,
.seventy-thirty,
.thirty-seventy,
.fifty-fifty {
    @apply flex flex-col md:grid md:grid-flow-row md:grid-cols-1 lg:grid lg:grid-flow-row lg:grid-cols-1;
}

.full-width {
    @apply md:grid-rows-1 md:auto-cols-fr;
}
.seventy-thirty,
.thirty-seventy {
    @apply md:grid-cols-10 md:place-items-start gap-4 lg:gap-8;
}
.seventy-thirty > div:first-of-type,
.thirty-seventy > div:last-of-type {
    @apply md:col-span-7;
}
.seventy-thirty > div:last-of-type,
.thirty-seventy > div:first-of-type {
    @apply md:col-span-3;
}
.fifty-fifty {
    @apply md:grid-cols-2;
}
.flex-reverse {
    @apply flex-col-reverse;
}