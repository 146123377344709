/* Slider */
.slick-slider {
	@apply relative block box-border select-none touch-pan-y mx-auto;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	@apply relative block overflow-hidden m-0 p-0;
}

.slick-list:focus {
	@apply outline-none;
}

.slick-list.dragging {
	@apply cursor-grab;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	@apply relative top-0 left-0 block before:table before:content-[''] after:table after:content-[''] after:clear-both;
}

.slick-loading .slick-track {
	@apply invisible;
}

.slick-slide {
	@apply hidden float-left h-full min-h-[1px]
}

[dir='rtl'] .slick-slide {
	@apply float-right;
}

.slick-slide img {
	@apply block;
}

.slick-slide.slick-loading img {
	@apply hidden;
}

.slick-slide.dragging img {
	@apply pointer-events-none;
}

.slick-initialized .slick-slide {
	@apply block;
}

.slick-loading .slick-slide {
	@apply invisible;
}

.slick-vertical .slick-slide {
	@apply block h-auto border border-transparent;
}

.slick-arrow.slick-hidden {
	@apply hidden;
}

.slick-prev,
.slick-next {
	@apply rounded-full bg-black absolute text-transparent overflow-hidden h-8 w-8 bg-no-repeat bg-center bottom-4 md:bottom-[34px] hover:bg-primary-dark focus:bg-primary-dark;
}

.slick-prev {
	@apply left-3;
	background-image: url(../../icons/icon-arrow-left-white.svg);
}

.slick-next {
	@apply right-3;
	background-image: url(../../icons/icon-arrow-right-white.svg);
}

.dark .slick-prev {
	background-image: url(../../icons/icon-arrow-left-black.svg);
}

.dark .slick-next {
	background-image: url(../../icons/icon-arrow-right-black.svg);
}

.slick-dots {
	@apply mt-8 px-14 pb-4 md:pb-8 text-center;
}

.slick-dots li {
	@apply inline-block;
}

.slick-dots li button {
	@apply mx-1 block rounded-full bg-blue-700 indent-[99999px] w-4 h-4 overflow-hidden;
}

.slick-dots li.slick-active button {
	@apply bg-secondary;
}

.slick-dots li:not(.slick-active) button:focus,
.slick-dots li:not(.slick-active) button:hover {
	@apply bg-blue-900;
}

.slick-slider--dark-mode {
	& .slick-prev,
	& .slick-next {
		@apply bg-blue-900 hover:bg-blue-800 focus:bg-blue-800;
	}
	& .slick-dots li:not(.slick-active) button {
		@apply bg-white hover:bg-gray-500 focus:bg-gray-500;
	}
}
